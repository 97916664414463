


































































































































































































































































































































































































































































.find-ch{
  &-filter{
    max-width: 280px;
    .badge-ml{
      border-radius: 16px;
    }
    .checkbox-entry span{
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  &-item{
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    align-items: center;
  }
}
.accordion-item{
  &.type-three{
    .accordion-header{
      display: flex;
      align-items: center;
    }
    .accordion-inner{
      padding-left: 0!important;
      padding-top: 10px!important;
    }
    .btn-accordion{
      margin-left: auto!important;
      flex: 0 0 20px;
      width: 20px;
      height: 20px;
    }
  }
}
.show-more{
  &-inner{
    display: none;
  }
  &.show{
    .btn{
      display: none;
    }
    .show-more-inner{
      display: block;
    }
  }
}
